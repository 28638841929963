// --------------------------------------------------------
/* list */

.sty--list-reset {
	margin-left: 0;
	margin-bottom: 0;
	list-style: none;
}

// 橫槓列表
.dash-list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	letter-spacing: rem-calc(0.8);

	li {
		position: relative;
		padding-left: rem-calc(10);
		padding-right: rem-calc(10);

		&:not(:last-child) {
			&::after {
				content: '';
				display: inline-block;
				position: absolute;
				z-index: 1;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				width: rem-calc(1);
				height: 1em;
				// background-color: $color-dark;
			}
		}
	}

	&.sty--dot {
		li {
			&:not(:last-child) {
				&::after {
					background-color: transparent;
					border-right: 0.0625rem dotted $primary-color;
				}
			}
		}
	}
}

// 圓形 icon 卡片列表
.iconCard {
	&-list {
		display: grid;
		gap: 1.25rem;
		grid-template-columns: repeat(2, 1fr);

		@include breakpoint(xlarge) {
			padding-top: 3.75rem;
			padding-bottom: 3.75rem;
			grid-template-columns: repeat(4, 1fr);
			gap: 3.125rem;
		}
	}

	&-card {
		display: block;
		padding: 1.25rem;
		text-align: center;
		background: linear-gradient(113.48deg, #F5F5F5 34.86%, #EAEAEA 63.31%, #EBE9E4 95.56%);
		border: .0625rem solid $card-border;
		color: $black;
		font-weight: 500;
		transition: .24s all;

		@include breakpoint(medium) {
			padding: 1.875rem 1.6875rem;
		}

		@include breakpoint(xlarge) {
			padding-top: 3.75rem;
			padding-bottom: 3.75rem;
		}

		.img-wrap {
			max-width: 7.5rem;
			margin: 0 auto 1.25rem;
			padding: 1.875rem;
			background-color: $white;
			border-radius: 50%;
			transition: .24s box-shadow;
			
	
			@include breakpoint(xlarge) {
				max-width: 11.25rem;
				margin-bottom: 2.5rem;
				padding: 3.125rem;
			}
		}

		&:is(a) {
			@include breakpoint-hover() {
				&:hover {
					border-color: $primary-400;

					.img-wrap {
						box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 8px 16px rgba(0, 0, 0, 0.1);
					}
				}
			}
		}
	}

	&-title {
		margin-bottom: .625rem;
		padding-bottom: .625rem;
		border-bottom: .0625rem solid $gray-400;
		color: inherit;
	}

	&-link {
		@include bulidFZ(12, 14);
		color: inherit;
	}
}

// 連結卡片列表
.linkCard {
	&-list {}

	&-card {
		position: relative;
		display: block;
		padding: 1.25rem 0;
		border-bottom: .0625rem solid $gray-400;
		color: $black;

		.be-icon {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			font-size: 2.5rem;
		}
	}

	&-title {
		padding-right: 2.5rem;
	}

	&-info {
		margin-top: .25rem;
		padding-right: 2.5rem;
	}}

.list--cjk {
	list-style-type: cjk-ideographic;
	margin-left: 2rem;
}

.list--decimal {
	list-style-type: decimal;
}
