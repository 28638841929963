/* 中文襯線字 */
// 從 HTML 載入 Google Font 設定
.font-nuosu {
	font-family: 'Nuosu SIL', serif;
}

.h1 {
	&-biger {
		@include bulidFZ(56, 90);
	}
}

.h2 {
	&-smaller {
		@include breakpoint(small down) {
			font-size: rem-calc(16);
		}
	}
}

// --------------------------------------------------------
// Txt
// --------------------------------------------------------
.text-em {
	&,
	a {
		color: $primary-color;
	}
}

.text-thin {
	font-weight: 300;
}

.text-medium {
	font-weight: 500;
}

.text-bold {
	font-weight: bold;
}

.text-underline {
	text-decoration: underline;
}

.text-italic {
	font-style: italic;
}
