// 麵包屑
.breadcrumbs {
	li {
		font-weight: 500;
	}

	a {
		&:hover {
			color: $primary-500;
			text-decoration: none;
		}
	}
}

// 返回link
.back-nav {
	margin-top: rem-calc(16);

	&_link {
		font-size: rem-calc(16);
		font-weight: 500;
		line-height: 1.5;
	}
}

// 頁碼
.pagination {
	display: flex;
	align-items: center;
	justify-content: center;

	&-nav {
		margin-top: rem-calc(30);

		@include breakpoint(large) {
			margin-top: rem-calc(60);
		}
	}

	.be-icon {
		font-size: rem-calc(24);
		color: $primary-color;

		@include breakpoint(large) {
			font-size: rem-calc(30);
		}
	}

	.pagination-item {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin: 0 rem-calc(3);
		padding: 0;
		min-width: rem-calc(30);
		height: rem-calc(30);

		@include breakpoint(large) {
			margin: 0 rem-calc(4);
			min-width: rem-calc(50);
			height: rem-calc(50);
		}

		&:not(.current):not(.previous):not(.next) {
			background-color: $white;
			// box-shadow: rem-calc(0 4 10) rgba($black, 0.08);

			// @include breakpoint(large) {
			// 	box-shadow: rem-calc(0 8 20) rgba($black, 0.08);
			// }

			// @include breakpoint-hover() {
			// 	&:hover {
			// 		color: $white;
			// 		background-color: $primary-color;
			// 	}
			// }
		}
	}

	.previous,
	.next {
		margin: 0;
		min-width: auto;
		height: auto;

		@include breakpoint(large) {
			min-width: 40px;
			height: 40px;
		}

		.pagination-link {
			border: 2px solid $primary-color;
			border-radius: 50%;
		}
	}

	.previous {
		margin-right: rem-calc(10);

		@include breakpoint(large) {
			margin-right: rem-calc(16);
		}
	}

	.next {
		margin-left: rem-calc(10);

		@include breakpoint(large) {
			margin-left: rem-calc(16);
		}
	}

	.previous.pagination-item,
	.pagination-next.pagination-item,
	.pagination-link {
		&::before,
		&::after {
			content: none !important;
		}
	}

	.pagination-link {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		width: 100%;
		height: 100%;
		font-weight: 500;
		line-height: 1.5;
		color: $gray-600;
		border: 1px solid $gray-600;

		@include breakpoint(large) {
			font-size: rem-calc(20);

			// hover 效果
			&:hover {
				color: $primary-500;
				border-color: $primary-500;
				transition-property: color, border-color;

				.be-icon {
					color: $primary-500;
					transition: 0.24 color;
				}
			}
		}
	}

	.disabled {
		pointer-events: none;
		a::before {
			content: none !important;
		}

		.pagination-link {
			border-color: $gray-300;
		}

		.be-icon {
			color: $gray-300;
		}
	}

	.current {
		pointer-events: none;

		.pagination-link {
			color: $primary-500;
			border-color: $primary-500;
		}

		@include breakpoint(large) {
			font-size: rem-calc(18);
		}
	}

	.ellipsis::after {
		padding: 0;
	}

	.be-icon {
		line-height: 1;
	}
}

// 內容導覽
.content-nav {
	padding: rem-calc(30);
	// background-color: rgba($color-bg-blue, 0.1);

	@include breakpoint(xlarge) {
		padding: rem-calc(60);
	}

	&_item {
		&:not(:last-child) {
			margin-bottom: rem-calc(30);
		}
	}

	&_link {
		@include bulidFZ(18, 24);

		line-height: calc(29 / 24);
		letter-spacing: 0.1em;

		.be-icon {
			opacity: 0;
			transform: translateX(-0.375rem);
			transition: 0.24s;
		}

		&.is-active {
			font-weight: bold;

			.be-icon {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@include breakpoint-hover() {
			&:hover {
				.be-icon {
					opacity: 1;
					transform: translateX(0);
				}
			}
		}
	}
}

// tabs
.sec-tab {
	.tabs {
		&-title {
			border: rem-calc(1) solid $gray-400;
			background: $gray-100;
			box-shadow: rem-calc(0 2 15) rgba(#210000, 0.02);
			transition: 0.24s;

			&:not(:last-child) {
				margin-right: rem-calc(10);
			}

			@include breakpoint($pad) {
				margin: rem-calc(0 14);
			}

			&.is-active {
				border-color: $primary-400;
			}

			@include breakpoint-hover {
				&:hover {
					border-color: $primary-400;
					color: $white;
					background-color: $primary-400;

					.tabs-link {
						color: $white;
					}
				}
			}
		}

		&-link {
			@include bulidFZ(16, 20);

			padding: rem-calc(14);
			letter-spacing: 0.04em;
			color: $black;
			transition: 0.24s;

			@include breakpoint($pad) {
				padding: rem-calc(16 24);
			}
		}
	}

	&.sty-transparent {
		.tabs-title {
			border: 0;
			background-color: transparent;
			box-shadow: none;

			&:hover {
				color: $black;

				.tabs-link {
					color: inherit;
				}
			}
		}
	}
}

// sticky
.data-sticky {
	max-width: 100% !important;
}

// 導覽列 navbar
.navbar {
	background: linear-gradient(88.96deg, rgba(#595959, 0.8) 7.21%, rgba(#2e2f33, 0.8) 45.93%);
	z-index: 11;

	&-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 60px;
	}

	&-title {
		font-size: 20px;
		line-height: 1.5;
		font-weight: 500;
		color: white;
		position: relative;
		padding-right: 40px;
		margin-right: 40px;
		display: none;
		flex-shrink: 0;

		@include breakpoint($pad) {
			display: block;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 1px;
			height: 18px;
			background: $gray-400;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&-list {
		display: flex;
		align-items: center;
		overflow-x: auto;
		padding: 9px 0;

		// lg以上, 間距100px
		&.gap-lg-10 {
			.navbar-item {
				&:not(:last-child) {
					@include breakpoint(large) {
						margin-right: rem-calc(100);
					}
				}
			}
		}
	}

	&-item {
		flex-shrink: 0;
		&:not(:last-child) {
			margin-right: rem-calc(15);

			@include breakpoint($pad) {
				margin-right: rem-calc(40);
			}
		}
	}

	&-link {
		display: block;
		font-size: 18px;
		line-height: 1.5;
		font-weight: 300;
		color: rgba(white, 0.7);
		padding: 3px 0;
		position: relative;

		&.is-active,
		&:hover,
		&:focus {
			color: white;

			// 虛線
			&::after {
				content: '';
				display: block;
				position: absolute;
				height: 2px;
				width: 10px;
				background: $gray-300;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	// 單元6.認識櫻花 下方的navbar
	&.navbar-dark {
		background: linear-gradient(88.96deg, #595959 7.21%, #2e2f33 45.93%);

		.navbar {
			&-wrap {
				@include breakpoint($pad) {
					min-height: 67px;
				}
			}

			&-title {
				font-weight: 700;
				padding-right: 0;
				margin-right: rem-calc(60);
				&::after {
					display: none;
				}
			}

			&-link {
				color: white;
				font-weight: 500;

				&::after {
					content: '';
					display: block;
					position: absolute;
					height: 2px;
					width: 100%;
					background: white;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}

				&.is-active,
				&:hover,
				&:focus {
					color: $primary-300;

					&::after {
						display: none;
					}
				}
			}
		}
	}

	// 海外有 logo
	&.has-logo {
		.img {
			position: absolute;
			left: -2.375rem;
		}
		.navbar-link {
			margin-left: 2.375rem;
			position: relative;
		}
	}
}

// 立即預約
.reserve-block {
	padding: 40px 0;
	background: $primary-200;

	@include breakpoint($pad) {
		padding: 14px 0;
	}

	&_content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@include breakpoint($pad) {
			flex-direction: row;
		}

		.img-wrap {
			flex-shrink: 0;
			position: relative;
			padding-bottom: 14px;
			margin-bottom: 14px;
			@include breakpoint($pad) {
				padding-right: 24px;
				margin-right: 24px;
				padding-bottom: 0;
				margin-bottom: 0;
			}

			&::after {
				content: '';
				position: absolute;
				background: $gray-600;
				width: 24px;
				height: 1px;
				left: 50%;
				top: 100%;
				transform: translate(-50%, -50%);

				@include breakpoint($pad) {
					width: 1px;
					height: 24px;
					left: 100%;
					top: 50%;
				}
			}

			.object-fit {
				width: rem-calc(247);
				&::before {
					padding-top: 14.17%;
				}
			}
		}

		.text {
			font-size: 18px;
			font-weight: 300;
			color: $gray-600;
			margin-bottom: 25px;
			@include breakpoint($pad) {
				margin-right: 50px;
				margin-bottom: 0;
			}
		}

		.btn {
			font-size: 18px;
			min-height: 45px;
			min-width: 125px;
		}
	}
}

// 上一則/下一則
.page-next-and-prev {
	margin-top: rem-calc(20);

	@include breakpoint(large) {
		margin-top: rem-calc(40);
	}

	&_wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.page-link {
		display: flex;
		align-items: center;

		.text {
			color: #000;
			font-size: rem-calc(16);
			line-height: 1.5;
			font-weight: 500;

			@include breakpoint(large) {
				font-size: rem-calc(20);
			}
		}

		&.page-prev {
			.text {
				margin-left: rem-calc(8);

				@include breakpoint(large) {
					margin-left: rem-calc(20);
				}
			}
		}
		&.page-next {
			.text {
				margin-right: rem-calc(8);

				@include breakpoint(large) {
					margin-right: rem-calc(20);
				}
			}
		}
	}

	.icon-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid $primary-500;
		border-radius: 50%;
		width: rem-calc(28);
		height: rem-calc(28);

		@include breakpoint(large) {
			width: rem-calc(40);
			height: rem-calc(40);
		}

		.be-icon {
			color: $primary-500;
			font-size: rem-calc(24);

			@include breakpoint(large) {
				font-size: rem-calc(30);
			}
			&::before {
				margin: 0;
			}
		}
	}
}
