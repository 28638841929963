// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：Layout樣式 Loader、Header、Footer

// -------------------------------------------------------

/* Loader */

// -------------------------------------------------------

%hdW {
	@include breakpoint(xlarge) {
		width: rem-calc(280);
	}

	@include breakpoint(xxlarge) {
		width: rem-calc(450);
	}
}

body {
	&.path-index {
		.hd {
			@include breakpoint(large) {
				transform: translateY(-100%);
				transition: transform 0.36s;

				&.is-show {
					transform: translateY(0%);
				}
			}
		}
	}
}

// --------------------------------------------------------
/* Header */
.hd {
	$root: &;

	position: fixed;
	z-index: 1001;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $white;
	box-shadow: rem-calc(0 15 25) rgba($black, 0.05);

	@include breakpoint(xxlarge) {
		box-shadow: rem-calc(0 30 50) rgba($black, 0.05);
	}

	&_cont {
		@include fc(flex-end, center);

		@include breakpoint(large) {
			justify-content: space-between;
		}
	}

	// Logo
	&_logo {
		@extend %hdW;

		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: rem-calc(13 25);
		transition: padding 0.24s;

		@include breakpoint(large) {
			padding-top: rem-calc(28);
			padding-bottom: rem-calc(28);
		}

		@include breakpoint(medium down) {
			margin-right: auto;
		}

		@include breakpoint(large) {
			padding-left: rem-calc(70);

			&::before,
			&::after {
				pointer-events: none;
				content: '';
				display: block;
				position: absolute;
				z-index: 5;
				top: 0;
				height: 100%;
			}

			&::before {
				left: 0;
				width: rem-calc(26);
				background: linear-gradient(
					168.81deg,
					#dabcbb -23.11%,
					#f8e2e1 35.61%,
					#edc7c5 72.16%,
					#db7c78 124.06%
				);
			}

			&::after {
				left: rem-calc(26);
				width: rem-calc(12);
				background: linear-gradient(
					168.81deg,
					#bd3b36 -23.11%,
					#dabcbb 12.54%,
					#f8e2e1 35.61%,
					#edc7c5 57.64%,
					#bd3b36 108.73%
				);
			}
		}

		@include breakpoint(xxlarge) {
			padding-left: rem-calc(88);
		}

		.img {
			width: rem-calc(110);

			@include breakpoint(large) {
				width: rem-calc(170);
			}
		}

		&.has-title {
			width: auto;

			.hd_img_title {
				color: #000;
				font-weight: 700;
				margin-left: rem-calc(30);
				padding-left: rem-calc(30);
				border-left: 1px solid #000;
				display: none;

				@include breakpoint(medium) {
					display: block;
				}
			}
		}
	}

	// 主選單
	&_main {
		$this: &;

		&_block {
			@include breakpoint(medium down) {
				position: fixed;
				z-index: 1002;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				// height: 0;
				overflow: hidden;
				background-color: $gray-100;
				transform: translateY(-30px);

				pointer-events: none;
				opacity: 0;
				transition: 0.36s opacity, 0.36s transform;
			}

			@include breakpoint(large) {
				@include fc(space-between, center);
			}
		}

		&_nav {
			white-space: nowrap;

			@include breakpoint(medium down) {
				overflow-y: auto;
				max-height: calc(100vh - 50px);
			}

			@include breakpoint(large) {
				@include fc(center, center);
			}
		}

		&_item {
			position: relative;

			@include breakpoint-hover {
				&:hover {
					.hd_main_link {
						font-weight: 700;
						color: $primary-500;
					}

					.hd_submain {
						pointer-events: all;
						opacity: 1;

						@include breakpoint(large) {
							transform: translate(-50%, 0px);
						}

						&.is-full {
							transform: translate(0%, 0px);
						}
					}
				}
			}

			&.is-open {
				@include breakpoint(medium down) {
					background-color: $white;
				}

				.hd_submain {
					pointer-events: all;
					max-height: rem-calc(600);
					opacity: 1;
				}

				.hd_main_link {
					.be-icon {
						transform: rotate(180deg);
					}
				}
			}

			.btn {
				padding: rem-calc(5 20);
				min-width: auto;
				min-height: auto;
				font-size: 0.875rem;
				line-height: 1.5;

				&:not(:last-child) {
					margin-right: 1rem;
				}
			}
		}

		&_link {
			@include fc(space-between, center);

			padding: rem-calc(20);
			font-weight: 500;
			white-space: nowrap;

			@include breakpoint(medium down) {
				&:hover {
					color: $black;
				}
			}

			@include breakpoint(large) {
				padding: rem-calc(23 10);
				line-height: 1;
			}

			@include breakpoint(xlarge) {
				padding: rem-calc(24 20);
			}

			&:not(:hover) {
				color: $black;
			}

			.be-icon {
				font-size: rem-calc(24);
			}

			// 展開
			&.is-open {
				.hd_submain {
					pointer-events: all;
					z-index: 999;
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		&_overlay {
			pointer-events: none;
			position: fixed;
			z-index: 0;
			top: 0;
			left: 0;
			right: 0;
			height: 100vh;
			background-color: rgba($black, 0.6);
			opacity: 0;
			transition: opacity 0.2s;
		}
	}

	// 複選單
	&_submain {
		$root: &;

		transition: max-height 0.3s, padding 0.3s, opacity 0.3s;
		overflow: hidden;

		@include breakpoint(medium down) {
			pointer-events: none;
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			transition: max-height 0.5s, opacity 0.5s;
		}

		@include breakpoint(large) {
			position: absolute;
			// top: 100%;
			left: 50%;
			width: rem-calc(276);
			line-height: calc(40 / 26);
			background-color: $white;
			box-shadow: 0px 30px 50px rgba(236, 236, 236, 0.8);
			border-top: rem-calc(1) solid $gray-200;
			pointer-events: none;
			opacity: 0;
			transform: translate(-50%, -10px);
			transition: transform 0.18s, opacity 0.18s;
			text-align: center;
		}

		&_nav {
			margin: 0;

			@include breakpoint(large) {
				padding: rem-calc(20 0);
			}

			@include breakpoint(xxlarge) {
				padding: rem-calc(45 0);
			}

			&_link {
				display: block;
				padding: rem-calc(16 20);
				letter-spacing: 0.04em;
				font-weight: 500;
				white-space: normal;

				@include breakpoint(medium down) {
					font-size: rem-calc(16);
					line-height: calc(20 / 16);
				}

				@include breakpoint(large) {
					padding-top: rem-calc(15);
					padding-bottom: rem-calc(15);
					font-size: rem-calc(20);
					line-height: calc(30 / 20);
					color: $black;
				}
			}
		}

		&_cover {
			width: 100%;
			max-width: rem-calc(345);

			@include breakpoint(medium down) {
				margin: 0 auto rem-calc(20);
			}

			@include breakpoint(large) {
				width: rem-calc(250);
			}

			@include breakpoint(xxlarge) {
				width: rem-calc(345);
			}

			&::before {
				padding-top: percentage(195/345);
			}
		}

		// 滿版
		&.is-full {
			@include breakpoint(medium down) {
				padding: rem-calc(0 20);
			}

			@include breakpoint(large) {
				@include fc(center, center);

				position: fixed;
				width: 100vw;
				left: 0;
				transform: translate(0, -10px);
			}

			#{$root}_nav {
				margin-bottom: rem-calc(15);

				@include breakpoint(medium) {
					@include fc(center, center);
					margin-bottom: rem-calc(20);
				}

				@include breakpoint(large) {
					margin-bottom: 0;
					margin-right: rem-calc(20);
					padding: rem-calc(35 0);
				}

				@include breakpoint(xxlarge) {
					margin-right: rem-calc(50);
					padding-top: rem-calc(60);
					padding-bottom: rem-calc(60);
				}

				&_item {
					@include breakpoint(medium) {
						&:not(:last-child) {
							border-right: rem-calc(1) solid $gray-300;
						}
					}

					@include breakpoint(medium down) {
						flex: 1;
					}

					@include breakpoint(small down) {
						@include fc(center, center);
						margin-bottom: rem-calc(20);
					}
				}

				&_link {
					flex: 1;

					&.vm-vip {
						&:hover,
						&:focus {
							color: $gold;
						}
					}
					@include breakpoint(small down) {
						padding: 0;

						&:not(:last-child) {
							padding-right: rem-calc(15);
							border-right: rem-calc(1) solid $gray-300;
						}

						&:last-child {
							padding-left: rem-calc(15);
						}
					}

					@include breakpoint(medium only) {
						text-align: center;
					}

					@include breakpoint(large) {
						padding: rem-calc(0 30);

						&:not(:last-child) {
							margin-bottom: rem-calc(30);
						}
					}

					@include breakpoint(xxlarge) {
						padding: rem-calc(0 50);
					}

					.img {
						@include breakpoint(large down) {
							width: rem-calc(60);
						}

						@include breakpoint($iphone_d down) {
							width: rem-calc(40);
						}
					}
				}
			}

			.txt {
				display: inline-block;
				margin-left: rem-calc(10);
				width: 4.16em;
				text-align: left;
			}
		}
	}

	// 右側選單
	&_ctrl {
		&_nav {
			@include fc(flex-end, center);
			@extend %hdW;

			// @include breakpoint(medium down) {
			// 	overflow-y: auto;
			// 	height: calc(100vh - 50px);
			// }

			@include breakpoint(large) {
				padding-right: rem-calc(37);
			}

			@include breakpoint(xlarge) {
				padding-right: rem-calc(50);
			}
		}

		&_item {
			&:not(:first-child) {
				@include breakpoint(xxlarge) {
					margin-left: rem-calc(20);
				}
			}
		}

		&_link {
			display: inline-block;
			font-weight: 500;

			&:not(:hover) {
				color: $black;
			}

			.be-icon {
				display: inline-flex;
				font-size: rem-calc(18);

				&::before {
					margin: 0;
				}

				@include breakpoint(large) {
					font-size: rem-calc(20);
				}

				@include breakpoint(xxlarge) {
					margin-right: rem-calc(12);
					font-size: rem-calc(24);
				}
			}

			@include breakpoint(1700px down) {
				.txt {
					display: none;
				}
			}

			@include breakpoint(xlarge down) {
				padding: rem-calc(16);
			}
		}

		&_open {
			padding: rem-calc(16);

			.be-icon {
				font-size: rem-calc(18);

				&::before {
					margin: 0;
				}
			}
		}

		&_close {
			display: block;
			margin-left: auto;
			padding: rem-calc(17);
			font-size: rem-calc(16);

			.be-icon::before {
				display: flex;
				margin: 0;
			}
		}
	}

	// 下滾樣式
	&.is-collapse {
		.hd_logo {
			line-height: 1;

			@include breakpoint(large) {
				padding-top: rem-calc(20);
				padding-bottom: rem-calc(20);
			}
		}
	}

	// 展開
	&.is-open {
		.hd_main_block {
			pointer-events: all;
			z-index: 999;
			opacity: 1;
			transform: translateY(0);
		}

		.hd_main_overlay {
			pointer-events: all;
			opacity: 1;
		}
	}
}

// --------------------------------------------------------
/* Footer */
.ft {
	$root: &;

	position: relative;
	padding-top: rem-calc(30);
	z-index: 1;
	background-color: $bg-footer;

	@include breakpoint($pad) {
		padding-top: rem-calc(60);
	}

	@include breakpoint(xxlarge) {
		padding-top: rem-calc(140);
	}

	&_cont {
		@include breakpoint($pad_d down) {
			padding: 0;
		}

		@include breakpoint-between($pad, 1024) {
			max-width: rem-calc(800);
			margin: auto;
		}
	}

	// Block: Top
	&_top {
		@include breakpoint(large) {
			@include fc(flex-start, flex-start);
			padding-bottom: rem-calc(70);
			border-bottom: rem-calc(1) solid $gray-300;
		}

		&_list {
			@include breakpoint($pad) {
				@include fc(flex-start, flex-start);
				flex: 3;
			}
		}

		&_item {
			transition: background 0.5s;

			@include breakpoint($pad) {
				flex: auto;
			}

			@include breakpoint(large) {
				flex: 1;
			}

			&.is-open {
				@include breakpoint($pad_d down) {
					background-color: $white;

					.list {
						&_title {
							.be-icon {
								transform: rotate(180deg);
							}
						}

						&_sublist {
							pointer-events: all;
							max-height: rem-calc(500);
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.list {
		&_title {
			@include fc(space-between, center);
			padding: rem-calc(19 20);
			color: $gray-600;

			@include breakpoint($pad) {
				margin-bottom: rem-calc(30);
				padding: 0;
			}

			.be-icon {
				font-size: rem-calc(24);

				@include breakpoint($pad) {
					display: none;
				}
			}
		}

		&_sublist {
			margin-left: 0;

			@include breakpoint($pad_d down) {
				pointer-events: none;
				max-height: 0;
				opacity: 0;
				overflow: hidden;
				transition: max-height 0.5s, opacity 0.5s;
			}

			&_item {
				&:not(:last-child) {
					@include breakpoint($pad) {
						margin-bottom: rem-calc(20);
					}

					@include breakpoint(large) {
						margin-bottom: rem-calc(30);
					}
				}
			}

			.link-txt {
				display: block;
				font-weight: 500;
				padding: rem-calc(16 20);

				@include breakpoint($pad) {
					display: inline-block;
					padding: 0;
					font-weight: 300;
				}
			}
		}
	}

	// Block: bottom
	&_bottom {
		padding: rem-calc(10 0 24);
		color: $gray-400;

		@include breakpoint($pad_d down) {
			padding-left: rem-calc(20);
			padding-right: rem-calc(20);
		}

		@include breakpoint(large) {
			@include fc(space-between, center);

			padding: rem-calc(24 0);
		}

		&_nav {
			@include breakpoint(medium down) {
				margin-bottom: rem-calc(12);
			}
		}

		.link-txt {
			@include bulidFZ(14, 16);

			position: relative;
			padding: rem-calc(0 13);
			line-height: calc(20 / 14);

			@include breakpoint(xxlarge) {
				padding: rem-calc(0 50);
			}

			&:not(:hover) {
				color: inherit;
			}

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			&:not(:last-child) {
				&::after {
					content: '';
					display: block;
					position: absolute;
					z-index: 1;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: rem-calc(2);
					height: rem-calc(11);
					background-color: #d9d9d9;
				}
			}
		}
	}

	&_follow {
		width: auto;
		flex: 0;

		@include breakpoint(medium down) {
			margin: rem-calc(20 0);
			text-align: center;
		}

		.follow_list {
			white-space: nowrap;
		}

		.follow {
			&--youtube {
				color: $youtube;
				@include breakpoint-hover() {
					&:hover {
						color: darken($youtube, 8%);
					}
				}
			}
			&--facebook {
				color: $facebook;
				@include breakpoint-hover() {
					&:hover {
						color: darken($facebook, 8%);
					}
				}
			}
			&--line {
				color: $line;
				@include breakpoint-hover() {
					&:hover {
						color: darken($line, 8%);
					}
				}
			}
		}

		.link-txt {
			display: inline-block;
			margin: rem-calc(0 15);

			@include breakpoint(large) {
				margin: 0;

				&:not(:last-child) {
					margin-right: rem-calc(20);
				}
			}
		}

		.be-icon {
			font-size: rem-calc(30);

			@include breakpoint(large) {
				font-size: rem-calc(40);
			}
		}
	}

	&_link {
		&_item {
			flex-shrink: 0;
		}
	}

	// Copyright
	&_copyright {
		font-weight: 300;
		letter-spacing: rem-calc(0.7);

		@include breakpoint($pad_d down) {
			max-width: rem-calc(315);
			margin: 0 auto;
		}

		@include breakpoint(large) {
			font-weight: 500;
		}
	}
}

// --------------------------------------------------------
// Loading
.loader {
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: all 0.8s;
	background-color: rgba($white, 0.85);

	&-img {
		$logoW: 70px;
		position: absolute;
		left: calc(50% - #{$logoW / 2});
		top: calc(50% - #{$logoW / 2});
		width: #{$logoW};
		animation: loading 3s infinite linear;
	}

	&.loaded {
		opacity: 0;
	}
}

@keyframes loading {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
