%inputStyle {
	@include bulidFZ(14, 18);

	margin-bottom: 0;
	padding: rem-calc(10 20);
	height: auto;
	line-height: calc(19 / 14);
	letter-spacing: 0.08em;

	@include breakpoint(medium) {
		padding-top: rem-calc(19);
		padding-bottom: rem-calc(19);
		line-height: calc(25 / 18);
	}

	&[disabled],
	&:read-only,
	&:disabled {
		// color: rgba($color-black5, 0.5);
		// background: rgba($color-black5, 0.05);
	}
}

input {
	&::placeholder {
		color: $gray-400;
	}
}

// --------------------------------------------------------
// 首頁 search 欄位
.search-box {
	position: relative;
}

.search-form {
	pointer-events: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	width: 100%;
	max-width: rem-calc(200);
	transform: translate(-50%, -50%);
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.24s;

	@include breakpoint(xxlarge) {
		max-width: rem-calc(275);
	}

	.search_ctrl {
		position: absolute;
		z-index: 1;
		top: 50%;
		right: rem-calc(10);
		transform: translateY(-50%);

		@include breakpoint(xxlarge) {
			right: rem-calc(14);
		}
	}

	.search_input {
		margin-bottom: 0;
		padding: rem-calc(12 20);
		padding-right: rem-calc(90);
		border: 0;
		height: auto;
		font-weight: 500;
		color: $gray-600;
		background-color: $gray-200;
		border-radius: rem-calc(100);

		@include breakpoint(xxlarge) {
			padding-top: rem-calc(23);
			padding-right: rem-calc(110);
			padding-bottom: rem-calc(23);
		}
	}

	.search_submit,
	.search_close {
		padding: rem-calc(10 5);

		@include breakpoint(medium) {
			padding: rem-calc(5);
		}

		@include breakpoint(xxlarge) {
			padding: rem-calc(10);
		}

		.be-icon {
			font-size: rem-calc(16);

			@include breakpoint(medium) {
				font-size: rem-calc(20);
			}

			&::before {
				margin: 0;
			}
		}
	}

	.search_submit {
		color: $gray-500;
	}

	.search_close {
		color: $gray-400;
	}

	&.is-open {
		pointer-events: all;
		opacity: 1;
	}
}

.hd {
	.search-form {
		min-width: rem-calc(150);
		left: rem-calc(-20);
		transform: translate(0%, -50%);

		@include breakpoint(medium) {
			left: rem-calc(-50);
			min-width: rem-calc(180);
		}

		@include breakpoint(xxlarge) {
			left: 0;
			min-width: rem-calc(286);
		}
	}

	.search_input {
		padding-top: rem-calc(5);
		padding-bottom: rem-calc(5);

		@include breakpoint(xxlarge) {
			padding-top: rem-calc(10);
			padding-bottom: rem-calc(10);
		}
	}
}

// --------------------------------------------------------
.form-box {
	$root: '.form';

	// label
	#{$root}_label {
		@include bulidFZ(18, 24);

		margin-bottom: rem-calc(8);
		line-height: calc(33 / 24);
		letter-spacing: 0.08em;
		font-weight: bold;

		@include breakpoint(medium) {
			margin-bottom: rem-calc(16);
		}

		&_txt {
			@include bulidFZ(12, 14);

			margin-left: rem-calc(10);
			line-height: calc(19 / 14);
			letter-spacing: 0.08em;
			// color: rgba($color-black5, 0.5);
			font-weight: 400;
		}
	}

	// 錯誤
	#{$root}_error {
		@include bulidFZ(14, 18);

		display: none;
		color: $color-error;
		line-height: calc(25 / 18);
		letter-spacing: 0.08em;

		.be-icon {
			margin-right: rem-calc(10);
			display: flex;
			font-size: 1.333em;

			&::before {
				margin: 0;
			}
		}
	}

	#{$root}_row {
		&:not(:first-child) {
			margin-top: rem-calc(30);

			@include breakpoint(medium) {
				margin-top: rem-calc(60);
			}
		}

		&.is-error {
			.input,
			.select,
			.textarea {
				border-color: $color-error;
			}

			#{$root}_error {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				margin-top: rem-calc(6);
			}
		}
	}
}

// --------------------------------------------------------
// 帶 icon 的 input
.icon-ctrl {
	&__icon {
		position: absolute;
		z-index: 1;
		top: 50%;
		right: rem-calc(20);
		transform: translateY(-50%);
		font-size: rem-calc(20);
		color: $primary-color;

		.be-icon {
			display: inline-flex;
			&::before {
				margin: 0;
			}
		}
	}
}

// --------------------------------------------------------
// select
.select-box {
	$root: '.select';

	position: relative;

	#{$root} {
		@extend %inputStyle;
		margin-bottom: 0;
		background-image: none;
	}

	#{$root}_box {
	}
}

.highlight-select {
	margin: 0 auto;
	max-width: rem-calc(712);

	.select {
		// border-color: rgba($color-bg-blue, 0.2);
		// box-shadow: inset 0 0 0 rem-calc(1) rgba($color-bg-blue, 0.2);
	}
}

// --------------------------------------------------------
// input
.input-group {
	$root: '.input';

	position: relative;

	#{$root} {
		@extend %inputStyle;
	}
}

// --------------------------------------------------------
// textarea
.textarea {
	padding: 1rem;
}

// --------------------------------------------------------
// checkboxContent 元件
.checkbox-content {
	$root: &;

	&_input[type='checkbox'] {
		margin: 0;
		display: none;

		+ #{$root}_label {
			display: flex;
			align-items: center;
			margin-left: 0;
			margin-right: 0;
		}

		&:not(:disabled) + #{$root}_label {
			cursor: pointer;
		}

		&:checked ~ #{$root}_label {
			#{$root}_icon-box {
				border-color: $primary-400;
				background: $primary-400;

				.be-icon {
					display: block;
				}
			}
		}
	}

	&_icon-box {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: rem-calc(6);
		height: rem-calc(24);
		width: rem-calc(24);
		border: rem-calc(2) solid $gray-400;
		transition: all 0.24s;
		background: $white;

		@include breakpoint(large) {
			margin-right: rem-calc(10);
		}

		.be-icon {
			font-size: rem-calc(14);
			color: $white;
			display: none;
			pointer-events: none;

			&::before {
				margin: 0;
			}
		}
	}

	&_text {
		font-size: rem-calc(16);
		font-weight: 300;
		color: $black;
		line-height: 1.5;

		@include breakpoint(large) {
			font-size: rem-calc(18);
			line-height: calc(24 / 18);
		}
	}
}
