// ★內頁 不需要 @include style.sass(layout)已經有
// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：內頁共用元件
//		- HTML Tag
//		- 表單(Form)相關元件
//		- 修改框架/套件
//		- keyframes

// --------------------------------------------------------

/* Tag */

html,
body {
	overflow-x: hidden;
	overflow-y: auto;

	&.stop_scrolling {
		overflow-y: hidden;
	}
}

// img {
// pointer-events: none;
// }

a {
	transition: 0.24s color;

	.be-icon {
		&::before {
			margin: 0;
		}
	}
}

button {
	cursor: pointer;
	color: inherit;
}

a,
button,
.button {
	&:focus {
		outline: none;
	}
}

ul,
ol {
	margin-left: 0;
	margin-bottom: 0;
	list-style: none;
}

// font
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
	letter-spacing: 0.04em;
}

h5,
.h5,
h6,
.h6 {
	letter-spacing: 0.02em;
}

h1,
.h1 {
	line-height: calc(50/35);

	@include breakpoint(xlarge) {
		line-height: calc(100/70);
	}
}

h2,
.h2 {
	line-height: calc(30/20);

	@include breakpoint(xlarge) {
		line-height: calc(50/36);
	}
}

h3,
.h3 {
	line-height: calc(27/18);

	@include breakpoint(xlarge) {
		line-height: calc(40/26);
	}
}

h4,
.h4 {
	line-height: calc(20/16);

	@include breakpoint(xlarge) {
		line-height: calc(30/20);
	}
}

p,
.p {
	line-height: calc(20/14);

	@include breakpoint(xlarge) {
		line-height: calc(22/16);
	}
}

// --------------------------------------------------------

/* 反選顏色 | Selection */
$selection-text-color: $white; // 反選的文字顏色
$selection-bg-color: darken($primary-color, 5%); // 反選的背景顏色

@mixin selection-color($text-color, $bg-color) {
	::selection {
		color: $text-color;
		background: $bg-color;

		// 當瀏覽器Tab非Focus狀態
		&:window-inactive {
			color: #323232;
			background: #c5c5c5;
		}
	}

	// Firefox版本: https://css-tricks.com/window-inactive-styling/#comment-92675
	// 但因為gulp-ruby-sass看不懂導致編譯錯誤(Invalid CSS)，所以無法使用
	// :-moz-window-inactive ::-moz-selection
	// 	color: #323232
	// 	background: #C5C5C5
}

// No Selection | 無法反選
// https://stackoverflow.com/a/4407335/11240898
@mixin user-select($val: none) {
	-webkit-touch-callout: $val; // iOS Safari
	-webkit-user-select: $val; // Safari
	-khtml-user-select: $val; // Konqueror HTML
	-moz-user-select: $val; // Firefox
	-ms-user-select: $val; // Internet Explorer/Edge
	user-select: $val; // Non-prefixed version, currently, supported by Chrome and Opera
}

// +selection-color(反選的文字顏色, 反選的背景顏色)
@include selection-color($selection-text-color, $selection-bg-color);

// 無法反選 | No Selection
.no-selection {
	@include user-select(none);
}

// --------------------------------------------------------

// Custom Scroll Bar
// Mixin 移動到　src\sass\layout\_variable_mixin_function.scss
// 需使用則開啟下列樣式
@include breakpoint-hover {
	@include custom-scroll-bar;

	.custom-scrollbar {
		@include custom-scroll-bar;
	}
}

// --------------------------------------------------------

/* Section */
.main-wrapper {
	position: relative;
	//  預留 header 空間
	padding-top: rem-calc(50);

	@include breakpoint(large) {
		padding-top: rem-calc(83);
	}

	// 首頁 large 以上, 沒有header
	body.path-index & {
		@include breakpoint(large) {
			padding-top: 0;
		}
	}
}

// 內容寬限制，有 padding
.grid-container {
	@include grid-padding;
	margin-left: auto;
	margin-right: auto;

	@include breakpoint(xxlarge) {
		max-width: rem-calc(1440 + $containerPaddingPc * 2);
	}

	&.content-center {
		@include breakpoint(medium only) {
			max-width: rem-calc(700);
		}
	}
}

// 內容寬限制，無 padding
.grid-container-p0 {
	margin-left: auto;
	margin-right: auto;

	@include breakpoint(xxlarge) {
		max-width: rem-calc(1440);
	}
}

// 麵包屑 / 返回上一頁
.page-nav {
	padding-top: 12px;

	@include breakpoint(large) {
		padding-top: 16px;
	}
}

// 主要內容 section 區塊
.page-main {
	padding-top: rem-calc(40);
	padding-bottom: rem-calc(40);

	@include breakpoint(large) {
		padding-top: rem-calc(100);
		padding-bottom: rem-calc(100);
	}
}

// --------------------------------------------------------

// 定義 SVG 隱藏於頁面使用
.svg-def {
	position: absolute;
	top: -9999px;
	left: -9999px;
	overflow: hidden;
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
	speak: never;
}

// --------------------------------------------------------

// Image
.img-absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.img-rel {
	position: relative;
}

.img-deco {
	position: absolute;
	top: 0;
	left: 0;
}

// --------------------------------------------------------
// 文字溢行/文字點點點
%clamp {
	--line-height: 1.5;

	position: relative;
	display: block;
	display: -webkit-box;
	overflow: hidden;
	max-width: 100%;
	line-height: var(--line-height);
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.clamp {

	&,
	&-1 {
		@extend %clamp;

		display: block;
		max-width: 100%;
		white-space: nowrap;
	}

	@function limit-height-get($i, $line-height) {
		@return calc(1em * #{$line-height} * #{$i});
	}

	@for $i from 2 through 6 {
		&-#{$i} {
			@extend %clamp;

			-webkit-line-clamp: $i;
			max-height: limit-height-get($i, 1.6);
			max-height: limit-height-get($i, var(--line-height));
		}
	}
}

// --------------------------------------------------------
/* object-fit */
// Fix the dimensions of an image and scale
// Default size: 1:1
//
// How to change size
// $imgWidth: // img width
// $imgHeight: // img height
// .object-fit:before{padding-top: round($imgHeight / $imgWidth * 100%)}
// round: round the number rounding
%object-fit-standard {
	position: relative;
	display: block; // Fixed <picture> bug
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	.img,
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100%;
		object-position: center center;
		background-position: center center;
	}

	.video-cover {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

%object-fit-cover {
	.img {
		object-fit: cover;
		background-size: cover;
	}
}

%object-fit-contain {
	background-position: center center;
	background-size: contain;

	.img {
		object-fit: contain;
		background-size: contain;
	}
}

.object-fit {
	@extend %object-fit-standard;
	@extend %object-fit-cover;

	// 沒有內層(before)
	// &--wrap {
	// 	@extend %object-fit-standard;
	// 	@extend %object-fit-cover;

	// 	&::before {
	// 		display: none;
	// 	}
	// }

	// 應對不同比例的照片
	&--contain {
		@extend %object-fit-standard;
		@extend %object-fit-contain;
	}
}

// --------------------------------------------------------
// loading
// .loader {
// 	position: fixed;
// 	z-index: 10000;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	background-color: rgba($white, 0.7);
// }

// --------------------------------------------------------

// 陰影
.box-shadow {
	box-shadow: rem-calc(0 4 10) rgba($black, 0.08);

	@include breakpoint(large) {
		box-shadow: rem-calc(0 8 20) rgba($black, 0.08);
	}
}

// --------------------------------------------------------

.lazy-preloader {
	pointer-events: none;
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #dddddd;
	opacity: 1;
	transition: 0.36s opacity;

	&::before {
		content: '';
		position: absolute;

		left: calc(50% - 20px);
		top: calc(50% - 20px);
		z-index: 10;
		transform-origin: 50%;
		width: rem-calc(40);
		height: rem-calc(40);
		border: 4px solid $primary-color;
		border-radius: 50%;
		border-top-color: transparent;
		animation: loading-circle 1s infinite linear;
	}

	.lazy.loaded+& {
		opacity: 0;
	}
}

.lazy {
	opacity: 0;
	transition: 0.36s opacity;

	&.loaded {
		opacity: 1;
	}
}

@keyframes loading-circle {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.media-embed {
	position: relative;

	&::before {
		content: '';
		display: block;
		padding-top: 56.25%;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100%;
		object-position: center center;
		background-position: center center;
	}
}

// --------------------------------------------------------

// go to top
.goto-top {
	@include fc(center, center);
	flex-direction: column;

	position: fixed;
	z-index: 999;
	right: rem-calc(20);
	bottom: rem-calc(20);
	width: rem-calc(45);
	height: rem-calc(45);
	font-size: rem-calc(14);
	line-height: calc(24/20);
	font-weight: 700;
	color: $black;
	border: rem-calc(3) solid transparent;
	background-color: $white;
	box-shadow: rem-calc(0 4 35) rgba($black, 0.1);
	transition: 0.24s all;

	opacity: 0;
	pointer-events: none;
	transform: translateY(#{rem-calc(30)});

	@include breakpoint(xxlarge) {
		right: rem-calc(30);
		bottom: rem-calc(30);
		width: rem-calc(80);
		height: rem-calc(80);
		font-size: rem-calc(20);
	}

	@include breakpoint-hover {
		&:hover {
			border-color: $primary-400;
		}
	}

	.be-icon {
		font-size: rem-calc(20);
		color: $primary-500;

		@include breakpoint(xxlarge) {
			font-size: rem-calc(40);
		}
	}

	&.is-show {
		transform: translateY(0);
		opacity: 1;
		pointer-events: all;
	}
}

// --------------------------------------------------------

// Section
.sec-bottom {
	padding: 2.5rem 1.25rem;
	text-align: center;
	background-color: $gray-100;

	@include breakpoint(large) {
		padding-top: 6.25rem;
		padding-bottom: 6.25rem;
	}

	.sec-title {
		margin-bottom: 1.25rem;
	}

	.sec-ctrl {
		margin-top: 2.5rem;
	}
}

// --------------------------------------------------------
// cookie
.cookie {
	&-box {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 10000;
		background-color: $primary-400;
		color: $white;
		padding: 1.25rem 0;
		transform: translateY(100%);
		transition: .7s transform;

		&.is-show {
			transform: translateY(0);
		}
	}

	&-cell {
		@include breakpoint(large) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&-btn-group {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;

		@include breakpoint(medium down) {
			margin-top: 1rem;
		}

		.btn {
			padding: rem-calc(5 20);
			min-width: auto;
			min-height: auto;
			font-size: .875rem;
			line-height: 1.5;

			&:not(:last-child) {
				margin-right: 1rem;
			}
		}
	}

	&_statement {
		padding-right: 1rem;
		font-size: .875rem;
	}
}