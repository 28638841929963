.table-scroll {
	overflow: auto;
}

.table-main {
	width: 100%;
	border: .0625rem solid $gray-400;
	border-collapse: collapse;
    color: $black;

	&-title {
		@include bulidFZ(16, 20);
		margin-bottom: 1.25rem;
		font-weight: 500;
		color: $black;
	}

	.table {
		&-tbody {
			.table-tr {
				@include breakpoint-hover() {
					&:hover {
						background-color: $primary-200;
					}
				}
			}
		}

		&-tr {
			@include bulidFZ(14, 20);
			border-bottom: .0625rem solid $gray-300;
			transition: 0.24s background;

			&:last-child {
				border-bottom: 0;

				.table-td {
					border-bottom: 0;
				}
			}

			&:nth-child(even) {
				background-color: $gray-100;
			}
		}

		&-th {
			position: relative;
			padding: 1rem 1.25rem;
			border-bottom: .125rem solid $primary-500;
			text-align: left;
			font-weight: 500;

			&:not(:last-child) {
				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: .0625rem;
					height: 1.875rem;
					background-color: $gray-300;
				}
			}
		}

		&-td {
			position: relative;
			padding: 1.25rem;
			border-bottom: .0625rem solid $gray-300;
			font-weight: 500;

			&:not(:last-child) {
				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: .0625rem;
					height: 1.875rem;
					background-color: $gray-300;
				}
			}
		}
	}

	.note {
		@include bulidFZ(14, 16);

		margin-left: .3125rem;
		color: $gray-400;
	}

	&.rwd-md {
		@include breakpoint(medium down) {
			.table-thead {
				display: none;
			}
		
			.table-tr {
				display: block;
				padding: 1.25rem;
			}
		
			.table-td {
				display: block;
				padding: 0;
				border: 0;
		
				&[data-title]::before {
					content: attr(data-title);
					display: inline-block;
					margin-right: .625rem;
					width: 3.5rem;
					color: $gray-400;
				}
		
				&::after {
					content: none;
				}
			}
		
			.note {
				display: block
			}
		}
	}

	&.rwd-sm {
		@include breakpoint(small down) {
			.table-thead {
				display: none;
			}
		
			.table-tr {
				display: block;
				padding: 1.25rem;
			}
		
			.table-td {
				display: block;
				padding: 0;
				border: 0;
		
				&[data-title]::before {
					content: attr(data-title);
					display: inline-block;
					margin-right: .625rem;
					width: 3.5rem;
					color: $gray-400;
				}
		
				&::after {
					content: none;
				}
			}
		
			.note {
				display: block
			}
		}
	}
}

.table-note {
	@include bulidFZ(14, 16);

	display: block;
	margin-top: .9375rem;
	color: $gray-400;
}