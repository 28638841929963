// 檔案下載列表
.file {
	&-block {
		&:not(:last-child) {
			margin-bottom: rem-calc(30);

			@include breakpoint(medium) {
				margin-bottom: rem-calc(60);
			}
		}
	}

	&-block-title {
		color: #000;
		font-weight: 500;
		border-bottom: 2px solid $gray-400;
		padding-bottom: rem-calc(10);
		margin-bottom: rem-calc(10);

		@include breakpoint(medium) {
			padding-bottom: rem-calc(20);
			margin-bottom: rem-calc(20);
		}
	}

	&-list {
		margin-bottom: rem-calc(20);
	}

	&-item {
		border-bottom: 1px solid $gray-400;
		padding-bottom: rem-calc(10);

		@include breakpoint(medium) {
			padding-bottom: rem-calc(20);
		}

		&:not(:last-child) {
			margin-bottom: rem-calc(10);

			@include breakpoint(medium) {
				margin-bottom: rem-calc(20);
			}
		}
	}

	&-card {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&-title {
		color: #000;
		font-weight: 500;
		max-width: 954px;
		margin-right: rem-calc(20);
	}

	&-link {
		flex-shrink: 0;
	}

	&-update {
		color: $gray-400;
		font-weight: 500;
	}
}
