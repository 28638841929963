@mixin font-icon {
	@font-face {
		font-family: "icon";
		src: url('../fonts/icons/icon.eot');
		src: url('../fonts/icons/icon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icons/icon.woff2') format('woff2'),
		url('../fonts/icons/icon.woff') format('woff'),
		url('../fonts/icons/icon.ttf') format('truetype'),
		url('../fonts/icons/icon.svg#icon') format('svg');
		font-display: block;
		unicode-range: U+EA01-EA1B;
	}

	.be-icon {
		font-family: sans-serif;
		line-height: 1;
	}

	.be-icon:before {
		@include font-icon-style;
	}
	
	/* Glyphs list */
	
	.be-icon-arrow-down:before {
		content: '\ea01';
	}
	
	.be-icon-arrow-l-left:before {
		content: '\ea02';
	}
	
	.be-icon-arrow-l-right:before {
		content: '\ea03';
	}
	
	.be-icon-arrow-l-top:before {
		content: '\ea04';
	}
	
	.be-icon-arrow-right:before {
		content: '\ea05';
	}
	
	.be-icon-arrow-up:before {
		content: '\ea06';
	}
	
	.be-icon-back:before {
		content: '\ea07';
	}
	
	.be-icon-check:before {
		content: '\ea08';
	}
	
	.be-icon-close:before {
		content: '\ea09';
	}
	
	.be-icon-consult:before {
		content: '\ea0a';
	}
	
	.be-icon-dots:before {
		content: '\ea0b';
	}
	
	.be-icon-environmental:before {
		content: '\ea0c';
	}
	
	.be-icon-fb:before {
		content: '\ea0d';
	}
	
	.be-icon-info:before {
		content: '\ea0e';
	}
	
	.be-icon-line:before {
		content: '\ea0f';
	}
	
	.be-icon-link_yt:before {
		content: '\ea10';
	}
	
	.be-icon-location:before {
		content: '\ea11';
	}
	
	.be-icon-play:before {
		content: '\ea12';
	}
	
	.be-icon-product-catalog:before {
		content: '\ea13';
	}
	
	.be-icon-product-manual:before {
		content: '\ea14';
	}
	
	.be-icon-product-report:before {
		content: '\ea15';
	}
	
	.be-icon-product-rohs:before {
		content: '\ea16';
	}
	
	.be-icon-scroll:before {
		content: '\ea17';
	}
	
	.be-icon-search:before {
		content: '\ea18';
	}
	
	.be-icon-service:before {
		content: '\ea19';
	}
	
	.be-icon-share:before {
		content: '\ea1a';
	}
	
	.be-icon-shop:before {
		content: '\ea1b';
	}
	
}

// <i>:before Style
@mixin font-icon-style {
	font-family: "icon";
	
	// Font smoothing. That was taken from TWBS
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-style: normal;
	font-weight: normal;

	// For safety - reset parent styles, that can break glyph codes
	font-variant: normal;
	text-transform: none;
	text-decoration: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// only necessary if not using the private unicode range (firstGlyph option)
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;

	// fix buttons height, for twitter bootstrap
	line-height: 1em;

	// Animation center compensation - margins should be symmetric
	// remove if not needed
	margin-left: .2em;

	// you can be more comfortable with increased icons size
	// font-size: 120%;

	// Uncomment for 3D effect
	// text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

// @include font-icon;

// SASS變數使用範例
// .icon {
//     &:before{
// 		@include font-icon-add(SVG檔案名稱);
//     }
// }

// 內頁使用之Style
%font-icon-style {
	@include font-icon-style;
}


$font-icon-arrow-down: '\ea01';

$font-icon-arrow-l-left: '\ea02';

$font-icon-arrow-l-right: '\ea03';

$font-icon-arrow-l-top: '\ea04';

$font-icon-arrow-right: '\ea05';

$font-icon-arrow-up: '\ea06';

$font-icon-back: '\ea07';

$font-icon-check: '\ea08';

$font-icon-close: '\ea09';

$font-icon-consult: '\ea0a';

$font-icon-dots: '\ea0b';

$font-icon-environmental: '\ea0c';

$font-icon-fb: '\ea0d';

$font-icon-info: '\ea0e';

$font-icon-line: '\ea0f';

$font-icon-link_yt: '\ea10';

$font-icon-location: '\ea11';

$font-icon-play: '\ea12';

$font-icon-product-catalog: '\ea13';

$font-icon-product-manual: '\ea14';

$font-icon-product-report: '\ea15';

$font-icon-product-rohs: '\ea16';

$font-icon-scroll: '\ea17';

$font-icon-search: '\ea18';

$font-icon-service: '\ea19';

$font-icon-share: '\ea1a';

$font-icon-shop: '\ea1b';


// 可以帶入變數
@mixin font-icon-add($icon: '', $extendStyle: true) {
	
	@if $icon == arrow-down {
		content: '\ea01';
	}
	
	@if $icon == arrow-l-left {
		content: '\ea02';
	}
	
	@if $icon == arrow-l-right {
		content: '\ea03';
	}
	
	@if $icon == arrow-l-top {
		content: '\ea04';
	}
	
	@if $icon == arrow-right {
		content: '\ea05';
	}
	
	@if $icon == arrow-up {
		content: '\ea06';
	}
	
	@if $icon == back {
		content: '\ea07';
	}
	
	@if $icon == check {
		content: '\ea08';
	}
	
	@if $icon == close {
		content: '\ea09';
	}
	
	@if $icon == consult {
		content: '\ea0a';
	}
	
	@if $icon == dots {
		content: '\ea0b';
	}
	
	@if $icon == environmental {
		content: '\ea0c';
	}
	
	@if $icon == fb {
		content: '\ea0d';
	}
	
	@if $icon == info {
		content: '\ea0e';
	}
	
	@if $icon == line {
		content: '\ea0f';
	}
	
	@if $icon == link_yt {
		content: '\ea10';
	}
	
	@if $icon == location {
		content: '\ea11';
	}
	
	@if $icon == play {
		content: '\ea12';
	}
	
	@if $icon == product-catalog {
		content: '\ea13';
	}
	
	@if $icon == product-manual {
		content: '\ea14';
	}
	
	@if $icon == product-report {
		content: '\ea15';
	}
	
	@if $icon == product-rohs {
		content: '\ea16';
	}
	
	@if $icon == scroll {
		content: '\ea17';
	}
	
	@if $icon == search {
		content: '\ea18';
	}
	
	@if $icon == service {
		content: '\ea19';
	}
	
	@if $icon == share {
		content: '\ea1a';
	}
	
	@if $icon == shop {
		content: '\ea1b';
	}
	

	@if $extendStyle == true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}