// --------------------------------------------------------
/* Txt color */
.clr--primary {
}
.clr--secondary {
}
.clr--success {
}
.clr--danger {
}
.clr--warning {
}
.clr--info {
}
.clr--dark {
}
.clr--link {
}

// --------------------------------------------------------
/* Btn */
// bulidBtnStyle(字體顏色, 編框顏色, 是否關閉背景)
@mixin bulidBtnStyle($color, $border, $hideBg: true) {
	color: $color;
	border-color: $border;
	@if $hideBg {
		background-color: $border;
	}
}

.button-group {
	flex-wrap: wrap;
	flex-grow: 1;
	margin-bottom: rem-calc(-12);

	@include breakpoint(medium) {
		margin-bottom: rem-calc(-20);
	}

	@include breakpoint(xlarge) {
		margin-bottom: rem-calc(-30);
	}

	@include breakpoint($iphone_d down) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.btn {
		margin-bottom: rem-calc(12);

		@include breakpoint($iphone) {
			margin-right: rem-calc(12);
		}

		@include breakpoint(medium) {
			margin-right: rem-calc(20);
			margin-bottom: rem-calc(20);
		}

		@include breakpoint(xlarge) {
			margin-right: rem-calc(30);
			margin-bottom: rem-calc(30);
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.btn {
	display: inline-flex;
    justify-content: center;
    align-items: center;
	padding: rem-calc(3 15);
	min-height: rem-calc(44);
	min-width: rem-calc(125);
	text-align: center;
	line-height: 1.5;
	letter-spacing: rem-calc(0.72);
	border-width: rem-calc(1);
	border-style: solid;
	border-color: inherit;
	transition: 0.24s all;
	// line-height: 1;

	@include bulidFZ(18, 26);

	@include breakpoint(medium) {
		padding: rem-calc(5 20);
		min-width: rem-calc(228);
		min-height: rem-calc(60);
		// border-width: rem-calc(2);
	}

	&-txt {
		padding-left: 0;
		padding-right: 0;
		min-width: 0;
		border-color: transparent;
	}

	// 尺寸
	&.sz--sm {
		min-width: auto;
		min-height: auto;
		font-size: rem-calc(16);
		line-height: 1.5;
		padding: rem-calc(5 20);

		@include breakpoint(medium) {
			font-size: rem-calc(18);
			padding: rem-calc(9 25);
		}
	}

	&.sz--md {
	}

	// 只有 icon 按鈕
	&.sz--icon {
		min-width: rem-calc(44);
		min-height: rem-calc(44);
		padding: 0;

		@include breakpoint(medium) {
			min-width: rem-calc(60);
			min-height: rem-calc(60);
		}
	}

	// 寬度
	&.w--md {
	}

	// 實心按鍵
	// 主色
	&.sty--main {
		@include bulidBtnStyle($white, $primary-color, $primary-color);

		@include breakpoint-hover() {
			&:hover {
				@include bulidBtnStyle($white, $primary-600);
			}
		}
	}

	// 次要色
	&.sty--secondary {
		@include bulidBtnStyle($white, $gray-600, $gray-600);

		@include breakpoint-hover() {
			&:hover {
				@include bulidBtnStyle($white, $primary-600);
			}
		}
	}

	// 黑色
	&.sty--dark {
		@include bulidBtnStyle($white, $black, $black);

		@include breakpoint-hover() {
			&:hover {
				@include bulidBtnStyle($white, lighten($black, 10%));
			}
		}
	}

	// 編框按鍵
	// 次要色
	&.sty--outline-secondary {
		@include bulidBtnStyle($gray-600, $gray-600, false);

		@include breakpoint-hover() {
			&:hover {
				@include bulidBtnStyle($primary-color, $primary-color, false);
			}
		}
	}

	// icon
	.be-icon {
		font-size: 24px;

		&::before {
			margin: 0;
		}
	}

	&:disabled {
		pointer-events: none;
		color: white;
		border-color: $gray-300;
		background: $gray-300;
	}

	&.inflex-center {
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
}

// --------------------------------------------------------
/* Link */

// --------------------------------------------------------
/* Txt */

// 底線主色連結
.link-underline {
	color: $primary-color;
	text-decoration: underline;
}

// 更多連結
.more-link {
	@include bulidFZ(18, 26);

	margin-top: rem-calc(30);
	color: $black;
	letter-spacing: 0.04em;

	@include breakpoint(large) {
		margin-top: rem-calc(50);
	}
}

//- btnDropdown 下拉選單按鈕
.btn-dropdown {
	color: #000;
	border: 1px solid $gray-400;
	background: white;
	min-width: 150px;
	padding: 8px 12px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.08);

	@include breakpoint(large) {
		min-width: 192px;
		padding: 16px 24px;
	}

	&_wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&_text {
		font-size: 16px;
		font-weight: 300;
		line-height: 1.5;
		letter-spacing: 0.02em;

		@include breakpoint(large) {
			font-size: 18px;
			line-height: calc(24 / 18);
		}
	}

	.be-icon {
		font-size: 24px;
		&::before {
			margin: 0;
		}
	}

	// 觸發 foundation dropdown元件 展開
	&.hover {
		.be-icon {
			transform: rotate(180deg);
		}
	}
}
