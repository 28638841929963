.modal {
	$root: &;

	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	opacity: 0;
	pointer-events: none;
	transition: 0.3s all;

	// open / close
	&.is-active {
		z-index: 9989;
		pointer-events: all;
		opacity: 1;

		.modal_inner {
			transform: translate(-50%, -50%) translateZ(0);
		}
	}

	// Inner
	&_inner {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		padding: rem-calc(30 20);
		width: calc(100% - 40px);
		max-width: rem-calc(1300);
		transform: translate(-50%, -50%) translateZ(0);

		@include breakpoint(large) {
			padding: rem-calc(60 20);
		}

		// 尺寸
		&.sz-sm {
			// max-width: rem-calc(340);
		}

		&.sz-lg {
			@include breakpoint(medium) {
				width: 80%;
				max-width: rem-calc(600);
			}

			@include breakpoint(large) {
				max-width: rem-calc(800);
			}

			@include breakpoint(xxlarge) {
				max-width: rem-calc(1030);
			}
		}

		&.sz-xlg {
			@include breakpoint(medium) {
				width: 80%;
				max-width: rem-calc(600);
			}

			@include breakpoint(large) {
				width: 90%;
				max-width: rem-calc(1030);
			}

			@include breakpoint(xxlarge) {
				max-width: rem-calc(1300);
			}
		}

		&.sz-fullpage {
			width: 100%;
			max-width: 100%;
			height: 100%;

			.modal_cnt {
				max-height: none;
				overflow: hidden;
			}

			.swiper-container {
				height: 100%;
			}

			.headline-box {
				position: absolute;
				bottom: rem-calc(64);
				width: 100%;
				left: 0;
				padding: 0 30px;
				color: $white;
			}

			.sec-title {
				font-size: rem-calc(16);
				line-height: 1.5;
				letter-spacing: 0.03125em;
			}
		}

		// 高度
		.main-box {
			min-height: inherit;
		}

		//- 設定手機版 576px 以下為滿版
		&.set-mb-fullpage {
			@include breakpoint(small down) {
				width: 100%;
				height: 100%;
				max-width: none;

				#{$root}_cnt {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	// Cont
	&_cnt {
		height: 100%;
		overflow-y: auto;
		background-color: $white;

		@include breakpoint(medium) {
			max-height: rem-calc(840);
		}

		@media screen and (min-width: 576px) and (max-height: 900px) {
			max-height: calc(840 / 1020 * 100vh);
		}
	}

	// 內層的關閉 btn
	&_close {
		cursor: pointer;
		position: absolute;
		z-index: 2;
		width: rem-calc(30);
		height: rem-calc(30);
		background-color: $white;
		border-radius: 50%;

		.be-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: rem-calc(15);

			@include breakpoint(medium) {
				font-size: rem-calc(28);
			}

			@include breakpoint(big) {
				font-size: rem-calc(50);
			}
		}

		&:not(.pos-inner) {
			top: rem-calc(-15);
			right: rem-calc(-15);

			@include breakpoint(medium) {
				top: rem-calc(-20);
				right: rem-calc(-20);
				width: rem-calc(40);
				height: rem-calc(40);
			}
	
			@include breakpoint(big) {
				top: rem-calc(-35);
				right: rem-calc(-35);
				width: rem-calc(70);
				height: rem-calc(70);
			}
		}

		&.pos-inner {
			top: rem-calc(15);
			right: rem-calc(15);
		}
	}

	// Overlay
	&_overlay {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.6);
	}

	// max-height
	&.set-max-hight {
		#{$root}_inner {
			overflow-y: auto;
			max-height: 80%;

			@media screen and (min-width: 1024px) and (min-height: 850px) {
				min-height: rem-calc(700);
			}
		}
	}

	&.sz-small {
		#{$root}_inner {
			max-width: 32.1875rem;
		}
	}
}

// --------------------------------------------------------
/* video */
// --------------------------------------------------------
.modal--video {
	.modal_inner {
		// position: relative;
		width: calc(100% - 36px);
		background-color: $black;

		@include breakpoint(medium) {
			width: 90%;
			max-width: rem-calc(960);
		}

		@include breakpoint(xxlarge) {
			max-width: rem-calc(1648);
		}

		@media screen and (min-width: 1440px) and (max-height: 1024px) {
			max-width: rem-calc(1200);
		}
	}

	.modal_cnt {
		max-height: none;
		background-color: transparent;
	}
}

.video-player {
	margin-bottom: 0;
}

// --------------------------------------------------------
/* filter */
// --------------------------------------------------------
.modal--filter {
	.modal {
		&_cnt {
			padding: 1.25rem;
			background: linear-gradient(113.48deg, #f5f5f5 34.86%, #eaeaea 63.31%, #ebe9e4 95.56%);
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 8px 16px rgba(0, 0, 0, 0.1);
	
			@include breakpoint(medium) {
				padding: 2.5rem;
			}
		}

		&_close {
			background-color: transparent;

			.be-icon {
				font-size: 1.875rem;
			}
		}
	
		&-title {
			color: #000;
			font-weight: 500;
			text-align: center;
			margin-bottom: rem-calc(20);
	
			@include breakpoint(large) {
				margin-bottom: rem-calc(30);
			}
		}
	
		&-subtitle {
			color: #000;
			font-weight: 300;
			margin-bottom: rem-calc(10);
		}
	
		&-option-wrap {
			&:not(:last-child) {
				margin-bottom: rem-calc(20);
				@include breakpoint(large) {
					margin-bottom: rem-calc(30);
				}
			}
		}
	
		&-btn-group {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			margin: rem-calc(0 -10 -10);
	
			.btn {
				font-size: 16px;
				min-width: rem-calc(120);
				min-height: 40px;
				margin: rem-calc(0 10 10);
				

				@include breakpoint(medium) {
					font-size: rem-calc(18);
					min-width: rem-calc(160);
					min-height: rem-calc(45);
				}
			}
		}
	}
	
	// select 樣式
	.select-box {
		.select {
			font-weight: 300;
			border: 1px solid white;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.02), 0px 2px 6px rgba(0, 0, 0, 0.08);

			@include breakpoint(large) {
				padding: rem-calc(16 24);
			}

			&:focus {
				border-color: $gray-400;
			}
		}

		.icon-ctrl {
			&__icon {
				font-size: rem-calc(24);
				color: #000;
				pointer-events: none;

				@include breakpoint(large) {
					right: rem-calc(24);
				}
			}
		}
	}
	
	// 地點格線
	.location-wrap {
		display: flex;
		flex-wrap: wrap;

		.form_row {
			padding: rem-calc(0 5);
			margin-top: 0;
			margin-bottom: rem-calc(10);

			&.city_row {
				width: 100%;
				@include breakpoint(medium) {
					width: 45%;
				}
			}

			&.district_row {
				width: 100%;
				@include breakpoint(medium) {
					width: 55%;
				}
			}
		}
	}
}