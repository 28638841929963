.brand-article {
	overflow: hidden;
	color: $black;

	&.is-reverse {
		.grid-container {
			@include breakpoint(large) {
				flex-direction: row-reverse;
			}
		}

		.brand-article_photo {
			@include breakpoint(large) {
				margin-left: rem-calc(80);
			}
		}

		.brand-article_text {
			@include breakpoint(large) {
				&::before {
					right: 0;
				}
			}
		}
	}

	&:not(.is-reverse) {
		.brand-article_photo {
			@include breakpoint(large) {
				margin-right: rem-calc(80);
			}
		}

		.brand-article_text {
			@include breakpoint(large) {
				&::before {
					left: 0;
				}
			}
		}
	}

	&.has-bg {
		@include breakpoint(large) {
			--bg-padding: #{rem-calc(80)};

			padding-top: var(--bg-padding);
			padding-bottom: var(--bg-padding);
		}

		@include breakpoint($laptop) {
			--bg-padding: #{rem-calc(120)};
		}

		.brand-article_photo {
			@include breakpoint(large) {
				&::after {
					z-index: -1;
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					display: block;
					width: 66.62%;
					height: calc(100% + var(--bg-padding) * 2);
					background-color: #f5f5f5;
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	.grid-container {
		@include breakpoint($pad) {
			width: 90%;
		}

		@include breakpoint(large) {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}
	}

	&_text {
		position: relative;
		padding-top: rem-calc(40);

		@include breakpoint(large) {
			flex: 1;
			width: 50%;
			max-width: rem-calc(590);
			padding-top: rem-calc(70);

			&::before {
				content: '';
				position: absolute;
				display: block;
				width: 200%;
				height: 1px;
				top: 0;
				background-color: $gray-400;
			}
		}
	}

	&_title {
		&::before {
			content: '';
			display: block;
			width: rem-calc(74);
			height: rem-calc(8);
			margin-bottom: rem-calc(20);
			background-color: $primary-color;

			@include breakpoint(large) {
				height: rem-calc(14);
			}
		}
	}

	&_subtitle {
		margin-top: rem-calc(10);
	}

	&_desc {
		margin-top: rem-calc(20);
	}

	&_btn {
		margin-top: rem-calc(20);

		@include breakpoint(large) {
			margin-top: rem-calc(40);
		}
	}

	&_photo {
		overflow: visible;

		@include breakpoint(large) {
			width: 50%;
			max-width: rem-calc(710);
		}

		&::before {
			padding-top: 66.62%;
		}

		p {
			position: absolute;
			z-index: 2;
			bottom: -1px;
			right: -1px;
			padding: rem-calc(16 16 8);
			width: 80%;
			background-color: $white;

			@include breakpoint(large) {
				padding: rem-calc(48 32 16);
				width: 70%;
			}
		}
	}
}