.card-wrapper {
	position: relative;
	z-index: 1;
	max-width: rem-calc(700);
	margin-left: auto;
	margin-right: auto;

	@include breakpoint(large) {
		max-width: rem-calc(1230);
	}
}

//---------------------------- 卡片
//- 商品卡片：首頁
.product-card-index {
	$root: '.product';

	box-shadow: 0px 3px 8px rgba($black, 0.25), inset 0px -5px 1px rgba($black, 0.25);

	@include breakpoint($pad) {
		box-shadow: 0px 4px 10px rgba($black, 0.25), inset 0px -6px 1px rgba($black, 0.25);
	}

	&[href] {
		position: relative;
		display: block;

		&::before {
			content: '';
			pointer-events: none;
			display: block;
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: rem-calc(1) solid $primary-600;
			box-sizing: border-box;
			opacity: 0;
			transition: opacity 0.24s;
		}
	}

	@include breakpoint-hover() {
		&[href]:hover::before {
			opacity: 1;
		}
	}

	#{$root}_cover {
		padding: rem-calc(24);
		background: linear-gradient(180deg,
				#f8f8f8 0%,
				#f6f6f6 20.59%,
				#eee8de 39.55%,
				#fdfbf7 68.36%,
				#f3eee6 93.21%,
				#e7e7e7 108.47%);

		@include breakpoint($pad) {
			padding: rem-calc(30);
		}
	}

	#{$root}_cont {
		padding: rem-calc(10 16 16);
		box-shadow: 0px 4px 10px rgba($black, 0.25), inset 0px -6px 1px rgba($black, 0.25);
		background: linear-gradient(88.96deg, #595959 7.21%, #2e2f33 45.93%);

		@include breakpoint(large) {
			padding: rem-calc(16 20 23);
		}
	}

	#{$root}_title {
		&_wrap {
			@include fc(flex-start, flex-start);
			@include bulidFZ(16, 20);

			margin-bottom: rem-calc(13);
			height: calc(2em * 1.5625);
			line-height: calc(25 / 16);

			@include breakpoint(large) {
				margin-bottom: rem-calc(16);
			}

			@include breakpoint(xlarge) {
				height: calc(2em * 1.5);
				line-height: calc(30 / 20);
			}
		}

		font-size: inherit;
		font-weight: 500;
		color: white;
		--line-height: inherit;
	}

	#{$root}_brief {
		font-weight: 300;
		color: $white;
	}
}

//- 關心卡片：首頁
.icare-card {
	$root: '.icare';
	@include fc(center, center);

	position: relative;
	margin: rem-calc(0 5 20);
	width: rem-calc(104);
	height: rem-calc(82);
	background-image: url(../images/common/icare-card-bg.png);
	background-position: right rem-calc(6) bottom rem-calc(10);
	background-size: auto percentage(182/200);
	background-repeat: no-repeat;
	background-color: $white;
	box-shadow: rem-calc(0 4 4) rgba($black, 0.25), inset 0px -4px 4px #a7a7a7;

	@include breakpoint(medium) {
		width: rem-calc(150);
		height: rem-calc(112);
	}

	@include breakpoint(large) {
		margin-left: rem-calc(8);
		margin-right: rem-calc(8);
		width: rem-calc(200);
		height: rem-calc(134);
	}

	&::before {
		content: '';
		pointer-events: none;
		display: block;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: rem-calc(1) solid $gray-600;
	}

	&[href] {
		position: relative;
		// display: block;

		&::after {
			content: '';
			pointer-events: none;
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			border: rem-calc(1) solid $primary-600;
			transition: opacity 0.24s;
		}
	}

	@include breakpoint-hover() {
		&[href]:hover {
			box-shadow: 0px 4px 8px rgba(255, 0, 0, 0.25), 0px 0px 20px rgba(227, 0, 0, 0.2),
				inset 4px 4px 4px rgba(255, 226, 153, 0.25), inset 0px -2px 4px #f17c7c;

			&::after {
				opacity: 1;
				box-shadow: inset 4px 4px 4px rgba(255, 226, 153, 0.25), inset 0px -4px 4px #f17c7c;
			}
		}
	}

	#{$root}_title {
		font-weight: 500;
		color: $black;
	}
}

//- 影片卡片：首頁
.video-card {
	$root: '.video';

	cursor: pointer;

	@include breakpoint($pad) {
		max-width: rem-calc(467);
	}

	// info
	#{$root}_info {
		position: relative;
		margin-bottom: 9%;
		transition: 0.24s transform;

		@include breakpoint($pad) {
			margin-bottom: rem-calc(15);
		}

		@include breakpoint(large) {
			margin-bottom: rem-calc(34);
		}
	}

	#{$root}_title {}

	#{$root}_descr {
		margin-bottom: rem-calc(10);
		font-weight: 300;
	}

	// Cover
	#{$root}_cover {
		position: relative;
		transition: transform 0.36s;
	}

	#{$root}_pic {
		position: relative;

		&::before {
			padding-top: percentage(262/467);
		}

		&::after {
			content: '';
			pointer-events: none;
			display: block;
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			border: rem-calc(3) solid $primary-400;
			transition: opacity 0.24s;
			box-sizing: border-box;
		}

		@include breakpoint-hover {
			&:hover {
				&::after {
					opacity: 1;
				}

				#{$root}_player {
					&_circle {
						fill: $primary-500;
					}

					&_path {
						fill: $white;
					}
				}
			}
		}
	}

	#{$root}_player {
		@include fc(center, center);

		cursor: pointer;
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		width: percentage(44/467);
		transform: translate(-50%, -50%);
		font-size: rem-calc(24);
		border-radius: 50%;
		color: $primary-500;
		box-shadow: rem-calc(0 4 20) rgba(210, 68, 63, 0.5);

		&_circle {
			fill: rgba($white, 0.8);
			transition: 0.24s all;
		}

		&_path {
			fill: $primary-500;
			transition: 0.24s all;
		}

		.be-icon {
			margin-left: rem-calc(3);

			&::before {
				margin: 0;
			}
		}
	}

	#{$root}_shadow {
		pointer-events: none;
		margin: rem-calc(20) auto 0;
		width: percentage(268/467);

		&::before {
			padding-top: percentage(38/268);
		}
	}
}

//- 文章卡片：首頁
.article-card-index {
	$root: '.article';

	display: block;
	padding: rem-calc(20);
	background-color: $gray-100;

	@include breakpoint(large) {
		padding: rem-calc(40);
	}

	@include breakpoint(xlarge) {
		padding: rem-calc(60);
	}

	// inner
	// tags
	#{$root}_tags {
		@include fc(flex-start, flex-start);

		margin: 0;
		margin-bottom: rem-calc(5);
		flex-wrap: wrap;

		@include breakpoint(large) {
			margin-bottom: rem-calc(16);
		}
	}

	#{$root}_tag {
		display: block;
		margin: rem-calc(0 10 14 0);
		padding: rem-calc(6 14);
		border-radius: rem-calc(5);
		font-size: rem-calc(14);
		line-height: calc(20 / 14);
		letter-spacing: 0.06em;
		font-weight: 500;
		color: black;
		background-color: white;
		border: 1px solid $gray-400;

		@include breakpoint(large) {
			margin: rem-calc(0 14 14 0);
		}

		@include breakpoint-hover() {
			transition: $transition all;
			&:hover {
				color: $white;
				background-color: $primary-400;
				border-color: $primary-400;
			}
		}
	}

	// info
	#{$root}_info {
		@include breakpoint(medium only) {
			margin-right: rem-calc(30);
		}

		@include breakpoint(large) {
			@include fc(flex-start, flex-start);
			flex: 1;
		}
	}

	#{$root}_title {
		color: $black;
		transition: $transition all;

		@include breakpoint(medium down) {
			margin-bottom: rem-calc(16);
		}

		@include breakpoint(large) {
			width: percentage(407/925);

			&:hover {
				color: $primary-500;
			}
		}
	}

	#{$root}_descr {
		color: $gray-600;
		font-weight: 300;

		@include breakpoint(medium down) {
			margin-bottom: rem-calc(16);
			line-height: calc(25 / 16);
		}

		@include breakpoint(large) {
			margin-left: rem-calc(30);
			flex: 1;
		}

		@include breakpoint(xlarge) {
			margin-left: rem-calc(51);
		}
	}

	// cover
	#{$root}_cover {
		@include breakpoint(medium) {
			width: rem-calc(300);
		}

		@include breakpoint(large) {
			margin-left: rem-calc(30);
		}

		@include breakpoint(xlarge) {
			width: rem-calc(345);
			margin-left: rem-calc(51);
		}

		&::before {
			padding-top: percentage(390/690);
		}
	}
}

//- 文章卡片：單元5 部落格
.article-card {
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	&_header {
		position: relative;

		.img-link {
			display: block;
		}

		.object-fit {
			&::before {
				padding-top: 66.67%;
			}
		}

		.state {
			position: absolute;
			top: 0;
			left: 0;
			padding: 14px;
			font-weight: 500;
			letter-spacing: 0.06em;
			color: white;
			background: $primary-500;
			padding: 6px 18px;
			pointer-events: none;

			&.bg-red {
				background: $primary-500;
			}

			&.bg-blue {
				background: #6387e3;
			}
		}
	}

	&_body {
		flex-grow: 1;
		background: #ffffff;
		border: 1px solid $gray-300;
		padding: rem-calc(12);

		@include breakpoint(large) {
			padding: rem-calc(20);
		}

		.date {
			font-weight: 300;
			color: $gray-600;
			margin-bottom: rem-calc(4);

			@include breakpoint(large) {
				margin-bottom: rem-calc(8);
			}
		}

		.title {
			font-weight: 500;
			color: #000;
			margin-bottom: rem-calc(4);

			@include breakpoint(large) {
				margin-bottom: rem-calc(8);
			}

			&:hover {
				color: $primary-color;
			}

			&-link {
				color: inherit;
				display: block;
			}
		}

		.text {
			color: #000;
			font-weight: 300;
		}

		.tag-list {
			margin-left: 0;
			display: flex;
			flex-wrap: wrap;
			margin-top: 8px;
			margin-right: rem-calc(-8);
			margin-bottom: rem-calc(-6);

			@include breakpoint(large) {
				margin-right: rem-calc(-10);
				margin-bottom: rem-calc(-8);
			}

			.tag {
				display: block;
				color: #000;
				font-size: rem-calc(12);
				font-weight: 500;
				border: 1px solid $gray-400;
				border-radius: 5px;
				padding: rem-calc(4 10);
				margin-right: rem-calc(8);
				margin-bottom: rem-calc(6);
				transition: none;

				@include breakpoint(large) {
					font-size: rem-calc(14);
					padding: rem-calc(6 14);
					margin-right: rem-calc(10);
					margin-bottom: rem-calc(8);
				}

				@include breakpoint-hover() {
					transition: $transition all;
					&:hover {
						color: $white;
						background-color: $primary-400;
						border-color: $primary-400;
					}
				}
			}
		}
	}

	&::before {
		position: absolute;
		content: '';
		display: block;
		pointer-events: none;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid #e87f89;
		box-sizing: border-box;
		opacity: 0;
		transition: opacity 0.24s;
	}

	&:hover {
		@include breakpoint(large) {
			&::before {
				opacity: 1;
			}
		}
	}
}

// 產品卡片：單元2 櫻花產品
.product-card {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px -6px 1px rgba(0, 0, 0, 0.25);

	&_header {
		padding: 35px 10px 20px;
		background: #ebeae7;
		position: relative;

		@include breakpoint(large) {
			padding: 35px 10px 35px;
		}

		.img-link {
			display: block;
		}

		.img {
			object-fit: cover;
		}

		.sakura-tag {
			position: absolute;
			top: 8px;
			left: 8px;
		}
	}

	&_body {
		padding: 10px 12px 12px;
		flex-grow: 1;
		color: white;
		background: linear-gradient(88.96deg, #595959 7.21%, #2e2f33 45.93%);
		border: 1px solid #000;

		@include breakpoint(large) {
			padding: 12px 20px 18px;
		}

		&:hover {
			color: white;
		}

		.title {
			font-size: 16px;
			font-weight: 500;
			min-height: 48px;

			@include breakpoint($pad) {
				font-size: 18px;
				min-height: 54px;
			}

			@include breakpoint(large) {
				font-size: 20px;
				min-height: 60px;
			}
		}

		.text {
			font-size: 14px;
			font-weight: 300;
			line-height: calc(22 / 16);

			@include breakpoint($pad) {
				font-size: 16px;
			}
		}

		.price-wrap {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			column-gap: 6px;

			@include breakpoint(large) {
				column-gap: 10px;
			}
		}

		.price {
			font-size: 16px;
			font-weight: 700;

			@include breakpoint($pad) {
				font-size: 18px;
			}

			@include breakpoint(large) {
				font-size: 20px;
			}
		}
	}

	&_cont {
		&::before {
			content: '';
			pointer-events: none;
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 1px solid $primary-400;
			box-sizing: border-box;
			opacity: 0;
			transition: opacity 0.24s;
		}

		&:hover {
			@include breakpoint(large) {
				&::before {
					opacity: 1;
				}
			}
		}
	}

	.compare {
		&-box {
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			padding: rem-calc(12);
			font-size: rem-calc(14);
			font-weight: 500;
			line-height: calc(20 / 14);
			color: $gray-600;

			@include breakpoint-hover() {
				.product-item:not(.is-prohibit) & {
					&:hover {
						color: $primary-500;

						.compare-link {
							color: inherit;
							border-bottom-color: inherit;
						}
					}
				}
			}
		}

		&-input {
			&[type='checkbox']:not(:disabled)+.compare-link {
				cursor: pointer;
			}

			&:checked+.compare-link {
				color: $primary-500;
				border-bottom-color: $primary-500;

				&::after {
					content: attr(data-selected);
				}
			}
		}

		&-link {
			position: relative;
			margin: 0;
			border-bottom: rem-calc(1) solid $black;

			&::after {
				content: attr(data-unselected);
			}
		}
	}
}

// icon 卡片
.policy {
	$root: &;

	&-list {
		display: flex;
		flex-wrap: wrap;
		margin: rem-calc(0 -10 -20);

		&.col-4 {
			#{$root}-item {
				@include breakpoint(medium) {
					width: 50%;
				}

				@include breakpoint($pad) {
					width: 25%;
				}
			}
		}
	}

	&-item {
		padding: rem-calc(0 10);
		margin-bottom: rem-calc(20);
		width: 100%;

		@include breakpoint($pad) {
			width: 33.33%;
		}
	}

	&-card {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		padding: rem-calc(20);
		height: 100%;
		background: $gray-200;
		border: 1px solid #d9d9d9;
		background: $gray-200;

		@include breakpoint($pad) {
			padding: rem-calc(40);
		}

		@include breakpoint-hover() {
			transition: 0.24s box-shadow;
			&:hover {
				box-shadow: 0 2px 5px 0 rgba(11, 11, 11, 0.2);
			}
		}

		.card-content {
			text-align: center;
		}

		.card-count {
			color: #000;
			font-weight: 500;
			margin-bottom: rem-calc(10);

			@include breakpoint($pad) {
				margin-bottom: rem-calc(20);
			}
		}

		.card-title {
			color: #000;
			font-weight: 500;
		}

		.img-wrap {
			flex-shrink: 0;
			width: rem-calc(90);
			height: rem-calc(90);
			background: white;
			border: 1px solid $gray-300;
			border-radius: 50%;
			margin-bottom: rem-calc(10);
			display: flex;
			justify-content: center;
			align-items: center;

			@include breakpoint(medium) {
				width: rem-calc(90);
				height: rem-calc(90);
			}

			@include breakpoint(large) {
				width: rem-calc(120);
				height: rem-calc(120);
				margin-bottom: rem-calc(14);
			}

			.object-fit {
				width: rem-calc(60);

				@include breakpoint(medium) {
					width: rem-calc(60);
				}

				@include breakpoint(large) {
					width: rem-calc(80);
				}
			}
		}

		.policy-title {
			color: #000;
			font-weight: 500;

			&:not(:last-child) {
				margin-bottom: rem-calc(8);
			}
		}

		.policy-text {
			color: #000;
			font-weight: 300;
		}
	}
}

// 純圖片卡片
.photo {
	&-list {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 1.25rem;

		@include breakpoint(medium) {
			grid-template-columns: repeat(3, 1fr);
			gap: 1.5625rem;
		}

		@include breakpoint(xlarge) {
			margin: 0 9.5rem;
		}
	}

	&-item {
		.img {
			width: 100%;
		}
	}
}