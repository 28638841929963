.main-banner {
	@include fc(center, center);

	position: relative;
	z-index: 1;
	padding: rem-calc(30 16);
	flex-direction: column;
	background: linear-gradient(119.46deg, #ddf1fa 23.23%, #f4f4f4 76.61%);

	@include breakpoint(large) {
		padding-top: rem-calc(90);
		padding-bottom: rem-calc(90);
	}

	@include breakpoint(xlarge) {
		min-height: rem-calc(310);
	}

	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(../images/banner/banner-deco.png) top;
		background-size: cover;
		background-repeat: no-repeat;
	}

	&_icon {
		margin-bottom: rem-calc(10);
		width: rem-calc(60);
		height: rem-calc(60);

		@include breakpoint(large) {
			margin-bottom: rem-calc(30);
			width: rem-calc(80);
			height: rem-calc(80);
		}
	}

	&_title {
		margin-bottom: rem-calc(10);
		letter-spacing: 0.2em;
		font-weight: 700;

		@include breakpoint(large) {
			margin-bottom: rem-calc(30);
			line-height: calc(73 / 60);
		}
	}

	&_descr {
		letter-spacing: 0.1em;

		@include breakpoint(large) {
			line-height: calc(29 / 24);
		}
	}
}

// 主題 banner
.topic-banner {
	padding: 80px 0;
	background: url('../images/themePlainning/theme-banner.jpg');
	background-size: cover;
	background-position: 80% 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	@include breakpoint($pad) {
		padding: 95px 0;
	}

	&_content {
		padding: 0 12px;
		width: 300px;
		text-align: center;

		@include breakpoint($pad) {
			width: 450px;
		}

		@include breakpoint(large) {
			width: 550px;
		}

		@include breakpoint($laptop) {
			width: 950px;
		}
	}

	&_title {
		font-size: 24px;
		font-weight: 500;
		color: #000;
		border-bottom: 1px solid $gray-400;
		padding-bottom: 16px;
		margin-bottom: 16px;

		@include breakpoint($pad) {
			font-size: 36px;
		}
	}

	&_text {
		font-size: 16px;
		font-weight: 300;
		color: #000;

		@include breakpoint($pad) {
			font-size: 20px;
		}
	}
}

// 搜尋 banner
.search-banner {
	padding: 30px 0;
	background: url('../images/themePlainning/theme-banner.jpg');
	background-size: cover;
	background-position: 80% 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	@include breakpoint($pad) {
		padding: 44px 0;
	}

	&_content {
		padding: 0 12px;
		width: 300px;
		text-align: center;

		@include breakpoint($pad) {
			width: 450px;
		}

		@include breakpoint(large) {
			width: 550px;
		}

		@include breakpoint($laptop) {
			width: 950px;
		}
	}

	&_title {
		font-size: 24px;
		font-weight: 500;
		color: #000;
		border-bottom: 1px solid $gray-400;
		padding-bottom: 16px;
		margin-bottom: 16px;

		@include breakpoint($pad) {
			font-size: 36px;
		}
	}

	&_text {
		color: #000;
		font-weight: 300;
		margin-bottom: rem-calc(15);

		@include breakpoint($pad) {
			margin-bottom: rem-calc(30);
		}
	}

	.search-input_wrap {
		max-width: 640px;
		margin: 0 auto;
		position: relative;
	}

	.search-input {
		height: auto;
		border: 1px solid white;
		border-radius: 100px;
		margin-bottom: 0;
		padding: rem-calc(10 50 10 20);

		@include breakpoint($pad) {
			padding: rem-calc(20 50 20 24);
		}
	}

	.search-btn {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);

		@include breakpoint($pad) {
			right: 24px;
		}

		.be-icon {
			font-size: 20px;
			display: flex;

			&::before {
				margin: 0;
			}
		}
	}

	.form_row {
		&.is-error {
			.search-input {
				border: 1px solid $primary-600;
			}
		}
	}
}

// 認識櫻花 banner
.detail-banner {
	padding: 1.875rem 1.25rem;
	color: $black;

	@include breakpoint($pad) {
		padding-top: 3.75rem;
		padding-bottom: 3.75rem;
	}

	@include breakpoint(large) {
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
	}

	&_inner {
		display: flex;
		flex-direction: column;
		max-width: 90rem;
		margin: 0 auto;
		padding: 1.5625rem 1.25rem;
		text-align: center;
		background: rgba($white, 0.85);
		border: .0625rem solid $gray-300;
		
		@include breakpoint($pad) {
			padding-top: 2.75rem;
			padding-bottom: 2.75rem;
		}

		@include breakpoint(large) {
			min-height: rem-calc(360);
		}
	}

	&_content {
		width: 100%;
		max-width: rem-calc(1030);
		margin: auto;
	}

	&_title {
		margin-bottom: .5rem;
	}

	&_text {
		line-height: calc(30/20);
	}

	&_btn {
		margin-top: 1.25rem;
	}
}

.banner-hr {
	margin: 1rem auto;
	max-width: 64.375rem;
	width: calc(100% - 2.5rem);
	background-color: $gray-400;
}

// 認識櫻花 新視覺banner
.kv-banner {
	padding: 3.125rem 2.5rem;
	height: calc(100vh - 3.125rem);
	max-height: 31.25rem;
	color: $black;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@include breakpoint($pad) {
		padding-top: 6.5625rem;
		padding-bottom: 6.5625rem;
		min-height: 37.5rem;
		max-height: 125rem;
	}

	@include breakpoint(large) {
		height: calc(100vh - 5.1875rem);
	}

	&_inner {
		max-width: 75rem;
		margin: 0 auto;
	}

	&_title {
		@include bulidFZ(28, 70);
		margin-bottom: 1.0625rem;
	}

	&_text {
		line-height: calc(30/20);
	}

	&_btn {
		margin-top: 2rem;
	}
}