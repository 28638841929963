
//- sakuraTag 櫻花標籤
.sakura-tag {
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: calc(16/14);
  color: white;
  background: #6387E3;
  padding: 5px 8px;
  border-radius: 2px 0 0 2px;
  pointer-events: none;

  @include breakpoint($pad){
    padding: 7px 11px;
  }
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 13px 0 0;
    border-color: #6387E3 transparent transparent transparent;
    @include breakpoint($pad){
      border-width: 15px 15px 0 0;
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 0 0 13px;
    border-color: transparent transparent transparent #6387E3;
    @include breakpoint($pad){
      border-width: 15px 0 0 15px;
    }
  }

  &.bg-blue {
    background: #6387E3;

    &::before {
      border-color: #6387E3 transparent transparent transparent;
    }

    &::after {
      border-color: transparent transparent transparent #6387E3;
    }
  }
  
  &.bg-red {
    background: $primary-400;

    &::before {
      border-color: $primary-400 transparent transparent transparent;
    }

    &::after {
      border-color: transparent transparent transparent $primary-400;
    }
  }
}