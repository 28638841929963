@use 'sass:map';
@import '../layout/variable_mixin_function';

// --------------------------------------------------------
// Padding/Margin Helper
// --------------------------------------------------------
// 為 5 的倍數
$baseSpace: 0.3125rem;

// 不要再增加，多加一個增加三百行
$spacings: (
	// 5
	1: $baseSpace * 1,
	// 10
	2: $baseSpace * 2,
	// 15
	3: $baseSpace * 3,
	// 20
	4: $baseSpace * 4,
	// 30
	6: $baseSpace * 6,
	// 40
	8: $baseSpace * 8,
	// 60
	12: $baseSpace * 12
) !default;

// margin + padding
// ex:
//    .p-1, .pl-1, .px-1, .py-1
//    .m-1, .ml-1, .mx-1, .my-1, mx-n1
//    .mb-sm-1, .pt-lg-2
// @mixin spacer($class, $css, $breakpoint:"", $offset:"") {
@mixin spacerContent($class, $css, $value, $size, $offset: '', $breakKey: '') {
	@if $offset == 'offset' {
		$offset: 'n';
		$value: -#{$value};
	}

	@if $value != '-auto' and $value != '-0' {
		.#{$class}#{$breakKey}-#{$offset}#{$size} {
			#{$css}: #{$value} !important;
		}
		.#{$class}t#{$breakKey}-#{$offset}#{$size} {
			#{$css}-top: #{$value} !important;
		}
		.#{$class}b#{$breakKey}-#{$offset}#{$size} {
			#{$css}-bottom: #{$value} !important;
		}
		.#{$class}l#{$breakKey}-#{$offset}#{$size} {
			#{$css}-left: #{$value} !important;
		}
		.#{$class}r#{$breakKey}-#{$offset}#{$size} {
			#{$css}-right: #{$value} !important;
		}
		.#{$class}x#{$breakKey}-#{$offset}#{$size} {
			#{$css}-left: #{$value} !important;
			#{$css}-right: #{$value} !important;
		}
		.#{$class}y#{$breakKey}-#{$offset}#{$size} {
			#{$css}-top: #{$value} !important;
			#{$css}-bottom: #{$value} !important;
		}
	}
}

@mixin spacer($class, $css, $offset: '') {
	$breakpoints: (
		'-sm': 'small',
		'-md': 'medium',
		'-lg': 'large',
	);
	$newSpacings: $spacings !default;
	@if $offset != 'offset' {
		$newSpacings: map-merge(
			(
				'auto': 'auto',
				0: 0,
			),
			$spacings
		);
	}
	@each $size, $value in $newSpacings {
		@include spacerContent($class, $css, $value, $size);
		@if $class == 'm' {
			@include spacerContent($class, $css, $value, $size, 'offset');
		}
	}
	@each $breakKey, $breakName in $breakpoints {
		@include breakpoint(#{$breakName} up) {
			@each $size, $value in $newSpacings {
				@include spacerContent($class, $css, $value, $size, '', $breakKey);
				@if $class == 'm' {
					@include spacerContent($class, $css, $value, $size, 'offset', $breakKey);
				}
			}
		}
	}
}

@include spacer('m', 'margin');
@include spacer('p', 'padding');

// --------------------------------------------------------
// 分隔線
// --------------------------------------------------------
.dot-hr {
	margin: rem-calc(20 0);
	border-bottom: rem-calc(1) dashed $primary-color;

	@include breakpoint(large) {
		margin: rem-calc(30 0);
	}
}
