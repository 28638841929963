// --------------------------------------------------------
// Tooltip
$toolDistance: rem-calc(3);
$tooltipVerPos: rem-calc(3); // 垂直
$tooltipHorPos: rem-calc(3); // 水平

.toolmsg {
	$root: &;
	position: relative;
	cursor: pointer;

	&_txt {
		pointer-events: none;
		position: absolute;
		z-index: 1;
		max-width: 20rem;
		padding: rem-calc(7 8);
		font-size: 0.8125rem;
		border-radius: 0.25rem;
		line-height: 1.4;
		letter-spacing: rem-calc(0.7);
		color: #fff;
		background: #333333;
		white-space: pre;
		text-align: left;
		opacity: 0;
		transition: 0.24s ease-out;
		transform: translateZ(0);

		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: rem-calc(5 0 5 7px);
			border-color: transparent transparent transparent #333333;
		}

		&::after {
			content: attr(data-msg);
			position: relative;
			z-index: 1;
			display: block;
		}
	}

	&:hover {
		#{$root}_txt {
			opacity: 1;
		}
	}

	// Pos
	&.pos {
		&--left {
			#{$root}_txt {
				top: 50%;
				right: 100%;
				transform: translate3d($toolDistance, -50%, 0);
			}

			&:hover {
				#{$root}_txt {
					transform: translate3d(-$tooltipVerPos, -50%, 0);
				}
			}
		}
		&--right {
			#{$root}_txt {
				top: 50%;
				left: 100%;
				transform: translate(-$toolDistance, -50%);
			}

			&:hover {
				#{$root}_txt {
					transform: translate($tooltipVerPos, -50%);
				}
			}
		}
		&--top {
			#{$root}_txt {
				bottom: 100%;
				left: 50%;
				transform: translate(-50%, $toolDistance);
			}

			&:hover {
				#{$root}_txt {
					transform: translate(-50%, -$tooltipHorPos);
				}
			}
		}
		&--bottom {
			#{$root}_txt {
				top: 100%;
				left: 50%;
				transform: translate(-50%, -$toolDistance);
			}

			&:hover {
				#{$root}_txt {
					transform: translate(-50%, $tooltipHorPos);
				}
			}
		}
	}
}
